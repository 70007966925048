import React from 'react';
import styled from 'styled-components';
import { FaExclamationCircle } from 'react-icons/fa';

import KeywordsPanel from './KeywordsPanel';
import SelectField from './SelectField';

const Container = styled.div`
    margin-bottom: 2rem;
    font-family: 'Neuzeit Grotesk';
`;
const KeywordsLabel = styled.label`
    font-size: 17px;
    line-height: 24px;
    text-align: left;
    letter-spacing: normal;
    user-select: none;
    color: rgba(25, 23, 26, 0.9);
    @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;
const Validation = styled.div`
    background: #f3cccc;
    color: #cc2929;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    font-family: 'Neuzeit Grotesk';
    border-radius: 2px;
    padding: 12px;
    display: flex;
    margin-left: 2px;
    margin-bottom: 16px;
`;

const KeywordsSelection = ({
    data,
    errors,
    register,
    control,
    watch,
    setValue,
    messageSent,
}) => {
    return (
        <Container>
            <KeywordsLabel>Keywords</KeywordsLabel>
            <SelectField data={data} control={control} />
            <KeywordsPanel
                data={data}
                errors={errors}
                register={register}
                setValue={setValue}
                watch={watch}
                messageSent={messageSent}
            />
            {errors.keywords && (
                <div
                    style={{
                        marginTop: 15,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    width: 10,
                                    height: 10,
                                    position: 'absolute',
                                    marginLeft: '50%',
                                    background: '#f3cccc',
                                    transform: 'rotate(45deg)',
                                    marginTop: -4,
                                }}
                            ></div>
                        </div>
                    </div>
                    <Validation>
                        <FaExclamationCircle size={24} color="#E63939" />
                        &nbsp;&nbsp;Please choose at least one keyword
                    </Validation>
                </div>
            )}
        </Container>
    );
};

export default KeywordsSelection;

import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';

const SelectContainer = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    border: 1px solid #bbb;
    border-radius: 4px;
    display: flex;
`;

const TitleGroup = styled.div`
    max-height: 350px;
    overflow: auto;
    flex: 4;
`;

const TitleList = styled.ul`
    margin: 0;
    padding: 0.75rem;
    list-style: none;
`;

const TitleItem = styled.li`
    font-family: 'Neuzeit Grotesk';
    font-size: 16px;
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover {
        background: #eee;
    }
`;

const CheckboxGroup = styled.div`
    flex: 6;
    max-height: 350px;
    overflow: auto;
`;

const CheckboxList = styled.div`
    padding: 0.8rem;
    & label {
        display: flex;
        margin-bottom: 5px;
    }
    & input {
        margin-top: 5px;
        margin-right: 8px;
    }
`;

const KeywordsPanel = ({ data, setValue, watch, messageSent }) => {
    const [selectedGroup, setSelectedGroup] = useState(1);
    const [flatSelectedKeywords, setFlatSelectedKeywords] = useState([]);
    const selectedKeywords = watch('keywords');

    useEffect(() => {
        if (selectedKeywords && selectedKeywords.length) {
            const flattenedSelected = selectedKeywords.map(el => el.label);
            setFlatSelectedKeywords(flattenedSelected);
        }
        if (!selectedKeywords && flatSelectedKeywords.length) {
            setFlatSelectedKeywords([]);
        }
        if (
            selectedKeywords &&
            !selectedKeywords.length &&
            flatSelectedKeywords.length === 1
        ) {
            setFlatSelectedKeywords([]);
        }
    }, [selectedKeywords, flatSelectedKeywords.length]);

    useEffect(() => {
        if (messageSent) {
            setFlatSelectedKeywords([]);
        }
    }, [messageSent]);

    const selectGroup = id => {
        setSelectedGroup(id);
    };

    const handleCheckboxChange = ev => {
        const isChecked = ev.target.checked;
        const val = ev.target.value;
        if (isChecked) {
            const newValue = selectedKeywords
                ? [...selectedKeywords, { value: val, label: val }]
                : [{ value: val, label: val }];
            setValue('keywords', newValue);
        } else {
            const newValue =
                selectedKeywords.length > 1
                    ? selectedKeywords.filter(el => el.label !== val)
                    : [];
            setValue('keywords', newValue);
        }
    };

    return (
        <Fragment>
            <SelectContainer>
                <TitleGroup>
                    <TitleList>
                        {data.map(group => (
                            <TitleItem
                                key={`title-${group.id}`}
                                onClick={() => {
                                    selectGroup(parseInt(group.id));
                                }}
                            >
                                {group.title}
                                <FaChevronRight size={16} color="#ccc" />
                            </TitleItem>
                        ))}
                    </TitleList>
                </TitleGroup>
                <CheckboxGroup>
                    {data.map(group => (
                        <CheckboxList
                            key={`list-${group.id}`}
                            style={{
                                display:
                                    selectedGroup === parseInt(group.id)
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            {group.options.map((opt, idx) => (
                                <label key={`chk-${group.id}-${idx}`}>
                                    <input
                                        type="checkbox"
                                        value={opt}
                                        name="keywords"
                                        onChange={handleCheckboxChange}
                                        checked={flatSelectedKeywords.includes(
                                            opt
                                        )}
                                    />
                                    {opt}
                                </label>
                            ))}
                        </CheckboxList>
                    ))}
                </CheckboxGroup>
            </SelectContainer>
        </Fragment>
    );
};

export default KeywordsPanel;

import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import CircleLoader from 'react-spinners/CircleLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import CTAComponent from '../components/cta';
import BrandsTicker from '../components/brandsTicker';
import TextInput from '../components/inputs/TextInput';

import BackImg from './../assets/images/wave21.png';
import BackMobileImg from './../assets/images/wave21-mobile.png';
import KeywordsSelection from '../components/keywordsSelection';

const Heading = styled.div`
    padding-top: 130px;
    display: flex;
    justify-content: center;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    img {
        object-fit: contain;
    }

    @media screen and (max-width: 767px) {
        width: 244px;
    }
`;
const Desc = styled.div`
    margin-top: 26px;
    font-size: 23px;
    line-height: 32px;
    color: white;
    font-family: 'Neuzeit Grotesk';
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-top: 31px;
    }
`;
const Background = styled.div`
    background: url(${BackImg}) no-repeat;
    background-size: 100%;
    background-position: top 275px left 0px;
    @media screen and (max-width: 767px) {
        background: url(${BackMobileImg}) no-repeat top;
        background-position: top 335px left 0px;
    }
`;
const IntroTitle = styled.h2`
    color: #19171a;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-family: 'Renogare-Regular';
    text-align: center;
`;
const IntroDescription = styled.p`
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(25, 23, 26, 0.9);
    margin-top: 12px;
    font-family: 'Neuzeit Grotesk';
    text-align: center;
`;
const FormContainer = styled.div`
    margin-top: 38px;
    margin-left: 64px;
    margin-right: 64px;
    background: white;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 24px;
    @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
        border-radius: 0;
        margin-bottom: 32px;
    }
`;
const FormFields = styled.form`
    display: ${props => (props.visible ? 'block' : 'none')};
    margin: 2rem auto 0 auto;
    width: 60%;
    @media screen and (max-width: 767px) {
        width: calc(100% - 24px);
    }
`;
const FormFooter = styled.div`
    display: flex;
    justify-content: center;
`;
const FooterDesc = styled.div`
    text-align: center;
    padding-right: 24px;
    padding-left: 14px;
    padding-bottom: 24px;
    margin-top: 4rem;
`;
const FooterDescParagraph = styled.div`
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(25, 23, 26, 0.9);
    margin-top: 12px;
    font-family: 'Neuzeit Grotesk';
    a {
        color: #b68f54;
        text-decoration: none;
    }
    @media screen and (max-width: 767px) {
        margin-top: 8px;
    }
`;
const Main = styled.div`
    margin-bottom: 184px;
    @media screen and (max-width: 767px) {
        margin-bottom: 136px;
    }
`;

const LoadingContainer = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;
    display: ${props => (props.visible ? 'flex' : 'none')};
    justify-content: center;
`;

const GoldenButton = styled.button`
    font-family: 'Renogare-Regular';
    display: flex;
    text-align: center;
    cursor: pointer;
    background: ${props =>
        props.disabled
            ? '#cccccc'
            : 'linear-gradient(-30deg, #ccaa66 0%, #996b3d 100%)'};
    border-radius: 24px;
    font-size: 12px;
    line-height: 24px;
    user-select: none;
    padding: 12px 24px;
    color: white;
    text-transform: uppercase;
    justify-content: center;
    width: auto;
    border: none;
    outline: none;
    &:hover {
        background: #806040;
        color: white;
    }
    &:active {
        background: #593f2d;
        color: rgba(255, 255, 255, 0.7);
    }
    a {
        color: white;
        text-decoration: none;
    }
    @media screen and (max-width: 767px) {
        font-size: 12px;
        color: white;
        letter-spacing: 0.5px;
        padding: 12px 18px;
    }
`;

const Success = styled.p`
    font-family: 'Renogare-Regular';
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1.5px;
    text-align: left;
    margin: 16px auto 38px auto;
    user-select: none;
    text-align: center;
    background: #f0f4c3;
    padding: 16px;
    width: 70%;
    @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.92px;
        margin-bottom: 17px;
    }
`;

const jsonUrl =
    'https://audigent-dashboard-assets.s3-us-west-2.amazonaws.com/corporate-site/iabCategories.json';

export default function ContextualPMPsMarketplacePage({ data }) {
    const {
        setValue,
        handleSubmit,
        register,
        errors,
        reset,
        control,
        watch,
    } = useForm();
    const [keywordData, setKeywordData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isSending, setIsSending] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    useEffect(() => {
        async function getData() {
            try {
                const response = await fetch(jsonUrl);
                if (response.status === 200) {
                    const data = await response.json();
                    setKeywordData(data);
                }
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        }
        getData();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (messageSent) {
            setValue('keywords', []);
        }
    }, [messageSent, setValue]);

    const onSend = data => {
        const { name, contactEmail, keywords, negativeKeywords } = data;
        setIsSending(true);

        const keywordsMessage = `\n\nContextualPMP Keywords: \n\n${keywords
            .map(el => el.label)
            .join('\n')}`;
        const negativeKeywordsMessage = `\n\nExclude these keywords:\n\n${negativeKeywords}`;

        const message = `${keywordsMessage}${
            negativeKeywords.length > 0 ? negativeKeywordsMessage : ''
        }`;

        const formData = {
            name,
            email: contactEmail,
            message,
            type: 2,
        };

        if (
            window &&
            window.location &&
            (window.location.hostname.indexOf('localhost') === 0 ||
                window.location.hostname.indexOf('dev.audigent.com') === 0)
        ) {
            console.log('Email sending disabled in dev & localhost', formData);
        } else {
            axios({
                method: 'post',
                url:
                    'https://jmwtq703p5.execute-api.us-west-2.amazonaws.com/default',
                data: formData,
            })
                .then(() => {
                    console.log('success');
                    setMessageSent(true);
                    setIsSending(false);
                    reset();
                    window && window.scrollTo(0, 0);
                })
                .catch(err => {
                    setIsSending(false);
                    console.log('error', err);
                });
        }
    };

    const {
        hero,
        data_partners,
        book_now,
        seo_content,
        form_introduction,
    } = data.butterPage;
    
    const footerDescParagraph = `Can't find the keywords you're looking for? <br /> No problem, contact us at <a href='mailto:info@audigent.com'>info@audigent.com</a> and we’ll make sure to get back to you as soon as possible.`;

    return (
        <Layout menu="FOR ADVERTISERS">
            <SEOComponent
                title="SmartPMP Search"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Background>
                <Heading>
                    <img src={hero[0].image} alt="" width="100%" />
                </Heading>

                <Desc>{hero[0].description}</Desc>
                {data_partners[0] &&
                    data_partners[0].logos &&
                    data_partners[0].logos.length > 0 && (
                        <BrandsTicker data={data_partners[0]} />
                    )}
                <Main>
                    <FormContainer>
                        <IntroTitle>{form_introduction.title}</IntroTitle>
                        <IntroDescription>
                            {form_introduction.description}
                        </IntroDescription>
                        {messageSent && (
                            <Success>
                                Thank you! Our team will be in touch soon.
                            </Success>
                        )}
                        <FormFields
                            onSubmit={handleSubmit(onSend)}
                            visible={!loading}
                        >
                            <KeywordsSelection
                                data={keywordData}
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                control={control}
                                watch={watch}
                                messageSent={messageSent}
                            />
                            <TextInput
                                name="negativeKeywords"
                                theme="default"
                                title="Negative Keywords"
                                status={errors.name ? 'error' : ''}
                                register={register}
                            />
                            <TextInput
                                name="name"
                                theme="default"
                                title="Name"
                                status={errors.name ? 'error' : ''}
                                register={register}
                                validation={{
                                    required: 'Please enter your name',
                                }}
                            />
                            <TextInput
                                name="contactEmail"
                                theme="default"
                                title="Email Address"
                                status={errors.contactEmail ? 'error' : ''}
                                register={register}
                                validation={{
                                    required: 'Please enter an email address',
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message:
                                            'Please enter a valid email address',
                                    },
                                }}
                            />
                            <FormFooter>
                                <GoldenButton
                                    type="submit"
                                    disabled={isSending}
                                >
                                    {isSending && (
                                        <Fragment>
                                            SENDING &nbsp;
                                            <BeatLoader
                                                color="#ffffff"
                                                size={3}
                                                loading={isSending}
                                            />
                                        </Fragment>
                                    )}
                                    {!isSending && (
                                        <div>Create ContextualPMP™</div>
                                    )}
                                </GoldenButton>
                            </FormFooter>
                        </FormFields>
                        <LoadingContainer visible={loading}>
                            <CircleLoader
                                size={150}
                                color="#b68f54"
                                loading={true}
                            />
                        </LoadingContainer>
                        <FooterDesc>
                            <FooterDescParagraph dangerouslySetInnerHTML={{__html: footerDescParagraph}} />
                        </FooterDesc>
                    </FormContainer>
                </Main>
                <CTAComponent {...book_now[0]} />
                <ReactTooltip delayShow={1} />
            </Background>
        </Layout>
    );
}

export const contextualPMPSMarketplacePageQuery = graphql`
    query {
        butterPage(slug: { eq: "contextual-pmp-marketplace" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                description
                image
            }

            data_partners {
                description
                logos {
                    title
                    image
                }
            }

            form_introduction {
                title
                description
            }

            book_now {
                button_title
                title
            }
        }
    }
`;

import React, { useState } from 'react';

import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';

import styled from 'styled-components';

const BrandsSection = styled.div`
    margin-top: 65px;
    z-index: 9;
    width: fit-content;
    margin-bottom: 40px;
    width: 100%;
    @media screen and (max-width: 767px) {
        margin-top: 33px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-bottom: 128px;
    }
`;
const BrandsTitle = styled.div`
    font-family: 'Neuzeit Grotesk';
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    margin-left: 232px;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
    }
`;
const BrandsContainer = styled.div`
    width: 100%;
    margin-top: 13px;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const Logo = styled.div`
    height: 96px;
    width: 150px;
    margin: 8px;
`;

const BrandsTicker = ({ data }) => {
    const [pageIsVisible, setPageIsVisible] = useState(true);

    const handleVisibilityChange = isVisible => {
        setPageIsVisible(isVisible);
    };

    return (
        <BrandsSection>
            <BrandsTitle>{data.description}</BrandsTitle>
            <BrandsContainer>
                <PageVisibility onChange={handleVisibilityChange}>
                    {pageIsVisible && (
                        <Ticker speed={7} direction="toRight">
                            {({ index }) => (
                                <Logo>
                                    <img
                                        src={
                                            data.logos[
                                                index % data.logos.length
                                            ].image
                                        }
                                        alt={
                                            data.logos[
                                                index % data.logos.length
                                            ].title
                                        }
                                        style={{
                                            objectFit: 'contain',
                                        }}
                                        width="100%"
                                        height="100%"
                                    />
                                </Logo>
                            )}
                        </Ticker>
                    )}
                </PageVisibility>
            </BrandsContainer>
        </BrandsSection>
    );
};

export default BrandsTicker;

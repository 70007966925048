import React, { useState, useEffect } from 'react';

import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

const selectBoxStyles = {
    container: provided => ({
        ...provided,
        width: '100%',
    }),
    multiValue: provided => ({
        ...provided,
        fontSize: '1.3rem',
    }),
    control: (_, { selectProps: { status } }) => ({
        borderRadius: '2px',
        outline: 'none',
        margin: '6px 0 24px',
        minHeight: '48px',
        transition: 'all 200ms ease-in-out',
        display: 'flex',
        border:
            status === 'error'
                ? '1px solid #E63939'
                : status === 'success'
                ? '1px solid #40992E'
                : '1px solid #C7C7C7',
        '&:hover': {
            border: '1px solid #ccaa66',
        },
        '&:focus': {
            border: '1px solid #ccaa66',
        },
    }),
};

const SelectField = ({ data, control }) => {
    const [selectMenuIsOpen, setSelectMenuIsOpen] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        const flatData = [];
        for (let i = 0; i < data.length; i++) {
            const entry = data[i];
            flatData.push({ value: entry.title, label: entry.title });
            for (let j = 0; j < entry.options.length; j++) {
                flatData.push({
                    value: entry.options[j],
                    label: entry.options[j],
                });
            }
        }
        setSelectOptions(flatData);
    }, [data]);

    const selectChange = value => {
        if (value) {
            setSelectMenuIsOpen(true);
        } else {
            setSelectMenuIsOpen(false);
        }
    };

    return (
        <Controller
            name="keywords"
            as={CreatableSelect}
            isMulti
            isClearable={false}
            placeholder={`Type to search from over 400 keywords, or add custom keywords`}
            options={selectOptions}
            styles={selectBoxStyles}
            rules={{
                required: true,
                validate: value => value.length > 0,
            }}
            control={control}
            defaultValue={[]}
            menuIsOpen={selectMenuIsOpen}
            onInputChange={selectChange}
            components={{ DropdownIndicator: null }}
        />
    );
};

export default SelectField;
